<template>
    <router-link :to="{name:'RecipeDetails', params: { recipe_slug: recipe.slug }}" class="recipe-item">
        <div class="recipe-item__badge" v-if="recipe.recipe_category">
            <img :src="src_url + recipe.recipe_category.image.path + recipe.recipe_category.image.file_name" class="w-100" />
        </div>
        <div class="recipe-item__img">
            <img v-if="itemSrc(recipe.thumb_image, 'w680h600') != 'no-image'"                
                :src="src_url + itemSrc(recipe.thumb_image, 'w680h660')"   
                class="img-fluid recipe-img"         
            />
        </div>
        <div class="recipe-item__data">
            <div class="name">{{recipe.name}}</div>
            <div class="tags">                
                <div class="other-tag" v-if="recipe.recipe_tag">
                    <div v-if="recipe.making_time"><span>{{recipe.making_time}}</span></div>
                    <div v-if="recipe.difficulty"><span>{{recipe.difficulty}}</span></div>
                    <div v-for="tag in recipe.recipe_tag" :key="tag.id"><span>{{tag.name}}</span></div>
                </div>
                <div v-else class="other-tag">
                    <div v-if="recipe.making_time"><span>{{recipe.making_time}}</span></div>
                    <div v-if="recipe.difficulty"><span>{{recipe.difficulty}}</span></div>
                </div>
            </div>
        </div>                
    </router-link>
</template>

<script>
export default {
    props:['recipe'],
    data(){
        return{
            src_url: process.env.VUE_APP_BIFROST_API_URL_NO_PROJECTNAME
        }
    },
}
</script>