<template>
    <router-link :to="'/gasztro#' + category.slug" class="recipe-category-item" :class="{'active': isActive}">
        <div v-inview="'fade-up'">
            <img  :src="src_url + itemSrc(category.image, 'w160h160')" v-if="itemSrc(category.image, 'w160h160') != 'no-image'" />
            <div v-inview="'fade-up'" class="name">{{category.name}}</div>
        </div>                            
    </router-link>
</template>

<script>
export default {
    props:['category', 'isActive']
}
</script>