<template>
    <section class="recipe-offer" >
        <div class="recipe-offer__category-wrap text-block text-block--left-text" >
            <b-container fluid>
                <b-row no-gutters class="recipe-offer__category-row">
                    <b-col cols="12" lg="6" class="text-block__content-wrap">
                        <div class="recipe-offer__lead" v-if="currentModule != null">
                            <div class="text-wrap text-block__content" >
                                <div class="text" v-html="currentModule.description" v-inview="'fade-up'"></div>
                                <a 
                                    v-if="currentModule.target_link" 
                                    :href="currentModule.target_link" 
                                    class="btn btn--gold-border btn--with-arrow" 
                                    v-inview="'fade-up'"
                                    @click="setGTM()"
                                ><span>tovább a receptekhez</span></a>
                            </div>              
                        </div>
                    </b-col>
                    <b-col cols="12" lg="6" class="recipe-offer__categories-wrap">
                        <!-- badges -->
                        <div class="recipe-offer__categories" v-if="categories.length">
                            <RecipeCategoryItem 
                                v-for="category in categories" :key="category.id" 
                                :category="category" 
                                :filter="category.name"                                 
                                :isActive="false"
                            />
                            <router-link 
                                :to="{ path: '/gasztro#osszes'}" 
                                class="recipe-category-item" 
                                v-inview="'fade-up'"                                
                                v-if="categories.length < 8"
                            >
                                <div>
                                    <img src="@/assets/images/gasztro/duhaj_badge.png" />
                                    <div class="name">Összes</div>
                                </div>                            
                            </router-link>
                        </div>
                    </b-col>
                </b-row>       
            </b-container>
        </div>
        <div class="recipe-offer__recipes-wrap" >
            <b-container fluid="xl">     
                <!-- Recipe items -->
                <b-row class="recipe-cards-wrap row" v-if="recipes.length>0">
                    <b-col cols="12" sm="6" v-for="recipe in recipes" :key="recipe.id">
                        <div class="recipe-item-wrap" >
                            <RecipeCard :recipe="recipe" v-inview="'fade-up'"/>
                        </div>                        
                    </b-col>                
                </b-row>             
            </b-container>
        </div>
    </section>
</template>

<script>
// Use for recipe offer block
// the module's data loading is in the view page - use getModuls mixin

//PROPS:
// identifier: the uniq identifier what the textblock component need. In the created section the text will be selected by this
//currentPageModulesTexts: it will be filling from the viewMI data currentPageModulesTexts  - this is the array where the text will be selected by the identifier

import RecipeCard from '@/components/pages/recipe/RecipeItem.vue'
import RecipeCategoryItem from '@/components/pages/recipe/RecipeCategoryItem'

export default {
    props:['identifier', 'currentPageModulesTexts', 'categories', 'recipes'],
    data(){
        return{
            currentModule: null,
            src_url: process.env.VUE_APP_BIFROST_API_URL_NO_PROJECTNAME
        }
    },   
    components:{
        RecipeCard,
        RecipeCategoryItem
    },
    created(){        
        let _this = this

        // this.getModuls(_this.identifier)    

        if (_this.currentPageModulesTexts.length) {            
            _this.currentModule = _this.currentPageModulesTexts.find(item => item.identifier == _this.identifier)
        } else {
            _this.currentModule = null
        }
        
    },
    methods:{
        setGTM(){
            let _this = this            

            this.GTtrackGA4({
                'event' : 'event',
                'category': _this.currentUrlName,
                'action': 'tovabb a receptekhez_kattintas',                
                'page_type': _this.currentUrlName,
                'button': 'tovabb_a_receptekhez',            
                'clicked_text': 'Tovább a receptekhez',
                'action_type': 'gomb_kattintas'
            })
        }
    }
}
</script>