<template>
    <section class="related-promos-games">
        <b-container fluid="xl">
            <b-row>
                <b-col cols="12" sm="6" class="related-promos-games-item-wrap" v-for="promo in relPromosGames" :key="promo.type + '_' + promo.id">                                        
                    <PromoGamesCard :promoGame="promo" v-inview="'fade-up'" :type="'promocio'"/>
                </b-col>
                <b-col cols="12" sm="6" v-if="!(relPromosGames.length % 2 === 0)" class="promo-games-placeholder-outer" v-inview="'fade-up'">
                    <div class="promo-games-placeholder-wrap">
                        <div class="promo-games-placeholder promo-games-placeholder--promo">
                            <img src="@/assets/images/icons/ic-promo-placeholder.svg" class="fluid-img" />
                            <div class="text-wrap">
                                <div class="text">soproni Promóciók</div>
                                <router-link class="btn btn--white-border btn--with-arrow" :to="{ name:'Promotions'}"><span>megnézem</span></router-link>
                            </div>
                        </div>
                        <div class="promo-games-placeholder promo-games-placeholder--games">
                            <img src="@/assets/images/icons/ic-games-placeholder.svg" class="fluid-img" />
                            <div class="text-wrap">
                                <div class="text">soproni játékok</div>
                                <router-link class="btn btn--white-border btn--with-arrow" :to="{ name:'Games'}"> <span>megnézem</span></router-link>
                            </div>
                        </div>
                    </div>
                </b-col>                
            </b-row>
        </b-container>
    </section>
</template>

<script>
import PromoGamesCard from '@/components/pages/promoGames/PromoGamesCard.vue'

export default {
    props:['relPromosGames'],
    components:{
        PromoGamesCard
    },
  
}
</script>