<template>
    <div class="video-player">
        <div class="video-player__inner" 
            @click="videoPlay" 
            :class="{'inactive': !isVideoPlay}" 
            ref="videoPlayerWrap"
        >   
            <div class="poster-wrap" v-if="!isVideoPlay && poster && !staticVideo">
                <img :src="poster" class="w-100" />
            </div>
            <video width="100%" ref="videoPlayer" v-if="!staticVideo">
                <source :src="video" type="video/mp4">
            </video>            
            <!-- STATIC VIDEO -->
            <div class="poster-wrap" v-if="!isVideoPlay && poster && staticVideo">
                <img :src="base_url + 'content/videok/posters/' + poster" class="w-100" />
            </div>
            <video  width="100%" ref="videoPlayer" v-if="staticVideo && poster" >
                <source :src="base_url + 'content/videok/' + staticVideoName" type="video/mp4">
            </video>            
            <button class="btn--play" v-if="!isVideoPlay"></button>
        </div>
        <button class="btn--scroll-down" v-if="!isVideoPlay && needScrollBtn" @click="scrollToNextBlock"></button>
    </div>
</template>

<script>
export default {
    props:['video', 'staticVideo', 'staticVideoName', 'needScrollBtn', 'poster'],
    data(){
        return {
            isVideoPlay: false,            
        }
    },        
    methods:{
        videoPlay(){    
            let _this = this                         

            if (_this.$refs.videoPlayer.paused){
                _this.$refs.videoPlayer.play()
                _this.isVideoPlay = true
            } else {
                _this.$refs.videoPlayer.pause()
                _this.isVideoPlay = false
                _this.showVideo = true
            }            
            
        },        
        scrollToNextBlock(){            
            let videoHeight = this.$refs.videoPlayerWrap.clientHeight

            window.scrollTo({
                left: 0, 
                top: videoHeight,
                behavior: 'smooth'
            });                 
                        
        }
    }
}
</script>