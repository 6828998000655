<template>
    <div class="pf-slider" :class="{'four-child': prodFamilies.length == 4 }" >
        <div 
            class="pf-slider__family-item" :class="prodFamily.css_class"
            v-for="prodFamily in prodFamilies" :key="prodFamily.id"                                   
        >
            <b-carousel 
            :interval="0"
            controls
            indicators      
            :fade="true" 
            v-inview="'fade-up'"           
        >        
                <b-carousel-slide
                    v-for="sitem in prodFamily.product" :key="sitem.id"
                                                
                >   
                    <template #img >                 
                        <!-- <router-link 
                            v-if="sitem.card_image" 
                            :to="{name: 'ProductDetails', params:{product_family_slug:  prodFamily.slug, product_slug: sitem.slug}}"
                        > -->
                            <img v-if="sitem.card_image" 
                                :src="src_url + itemSrc(sitem.card_image, 'w282h600')"
                                :alt="sitem.name"
                                class="main-img img-fluid"                                  
                            />       
                        <!-- </router-link>                                                                        -->
                        <img v-else                           
                            src="@/assets/images/noimage/no-image-product.png"                            
                            class="empty-img img-fluid"  
                        />
                    </template>                             
                </b-carousel-slide>
            </b-carousel>
            <div class="slider__content carousel-caption"  >
                <div class="container" >
                    <b-row>
                        <b-col cols="12">
                            <div class="slider__inner-cont">
                                <div class="slider__text-wrap">
                                    <div class="slider__title" v-inview="'fade-up'">{{prodFamily.name}}</div>
                                    <div class="btn-wrap" v-inview="'fade-up'">
                                        <router-link 
                                            :class="prodFamily.identifier == $store.state.prodFamiliesId.ovatos_duhaj || prodFamily.identifier == $store.state.prodFamiliesId.lager ? 'btn btn--white-border btn--with-arrow' : 'btn btn--green-border btn--with-arrow'"
                                            :to="{name:'ProductFamily', params:{product_family_slug: prodFamily.slug}}"
                                            @click.native="setGTM()"
                                            
                                        >
                                            <span>A termékcsaládhoz</span>
                                        </router-link>
                                        <a v-if="prodFamily.shop_link"
                                            :href="prodFamily.shop_link" target="_blank" 
                                            :class="prodFamily.identifier == $store.state.prodFamiliesId.ovatos_duhaj || prodFamily.identifier == $store.state.prodFamiliesId.lager ? 'btn btn--white-border btn--shop no-text' : 'btn btn--green-border btn--shop no-text'" @click="setGTMCart()">
                                        </a>
                                    </div>                                           
                                </div>                                        
                            </div>
                        </b-col>
                    </b-row>
                </div>                    
            </div>
        </div>
    </div>
</template>

<script>
import apiH from '@/mixins/apiHandlerMI.js'

export default {    
    props:['prodFamiliesAll', 'needApiCall'],
    mixins: [apiH],
    data(){
        return{
            src_url: process.env.VUE_APP_BIFROST_API_URL_NO_PROJECTNAME,
            prodFamilies: [],            
        }
    },       
    created(){
        if (this.needApiCall){
            this.getProdFamilies()
        } else {
            this.prodFamilies = this.prodFamiliesAll
        }
        
    },
    watch:{
         $route(){
            this.prodFamilies = [],            
            this.getProdFamilies()
        }
    },   
    methods:{
        getProdFamilies(){
            let _this = this

            _this.get('product_category',
                {
                    params: {                          
                        relations:1,                                       
                        where: [
                            ['where','status', '1'],                                                
                        ],
                        relations_params:{
                            product:{
                                select: ['name', 'shop_link', 'slug', 'card_image'],
                                where: [['where', 'status', '1']],
                                order_by:[['sort_order', 'asc'], ['name', 'asc']]
                            }
                        }

                    }
                },
                function(resp){                                        
                    _this.prodFamilies = resp.data.product_category
                    if (_this.$route.params.product_family_slug) {
                        //if the route has product_family_slug
                        _this.getCurrentProdFamily()
                    }                                         
                }, function(err){
                    console.log(err);
                    _this.prodFamilies = []
                }
            )
        },
        getCurrentProdFamily()  {
            let _this = this
            let currentSlug = this.$route.params.product_family_slug 

            // console.log('getcurrentprodf')
            this.prodFamilies.forEach( element => {
                if (element.slug == currentSlug ) {
                    // _this.currentProdFamily = element
                    var currentPFItemIndex =  _this.prodFamilies.indexOf(element)
                    if (currentPFItemIndex > -1) {
                        _this.prodFamilies.splice(currentPFItemIndex, 1);
                    }
                }
            })

        },
        setGTM(){            
            let _this = this                     
                    
            this.GTtrackGA4({
                'event' : 'event',
                'category': _this.currentUrlName,
                'action': 'tovabbi_reszletek_kattintas',                
                'page_type': _this.currentUrlName,
                'button': 'termekcsalad',            
                'clicked_text': 'A termékcsaládhoz',
                'action_type': 'gomb_kattintas'
            })
        },
        setGTMCart(){            
            let _this = this       

            this.GTtrackGA4({
                'event' : 'event',
                'category': _this.currentUrlName,
                'action': 'kosar_gombra_kattintas',                
                'page_type': _this.currentUrlName,
                'button': 'kosar',                            
                'action_type': 'gomb_kattintas'
            })
        }
    }
}
</script>