<template>
    <div class="product-item product-item--little" v-if="product != null">        
        <img v-if="product.card_image"                             
            :src="src_url + itemSrc(product.card_image, 'w282h600')"
            :alt="product.name"
            class="main-img img-fluid" 
            v-inview="'fade-up'" 
        />
        <img v-else                           
            src="@/assets/images/noimage/no-image-product.png"                            
            class="empty-img img-fluid"  
        />
        <div class="name" v-inview="'fade-up'">{{product.name}}</div>
        <div class="btn-wrap" v-inview="'fade-up'">
            <router-link 
                v-if="product_family_identifier === $store.state.prodFamiliesId.ovatos_duhaj || product_family_identifier === $store.state.prodFamiliesId.lager"
                :to="{name:'ProductDetails', params:{product_family_slug:product_family_slug, product_slug: product.slug }}" 
                :class="product_family_class == $store.state.prodFamiliesClasses.class_pf_ovatos_duhaj || product_family_class == $store.state.prodFamiliesClasses.class_pf_lager ? 'btn btn--white-border btn--with-arrow' : 'btn btn--green-border btn--with-arrow'"
                @click.native="setGtmDetails()"
            >
               <span>Részletek</span>
            </router-link>        
            <button
                v-else-if="nutritionData"
                :to="{ path: product.data_sheet}" 
                class="btn btn--green-border btn--with-arrow"
                v-b-modal="'nutrient-table_' + product.slug + ''"
            >
                <span>Tápanyagtáblázat</span>
            </button>     
            <a 
                :class="product_family_class == $store.state.prodFamiliesClasses.class_pf_ovatos_duhaj || product_family_class == $store.state.prodFamiliesClasses.class_pf_lager ? 'btn btn--white-border btn--shop no-text' : 'btn btn--green-border btn--shop no-text'"
                :href="product.shop_link" target="_blank" 
                v-if="product.shop_link"
                @click="setGTMCart(product.name)"
            ></a>
        </div>
        <!-- TAPANYAGTABLAZAT MODAL -->
        <b-modal v-if="nutritionTable"
            :id="'nutrient-table_' + product.slug" 
            size="lg" 
            hide-header-close hide-header
            hide-footer
            centered 
        >
            <div class="nutrition-modal">
                <div v-if="nutritionData" v-html="nutritionData.description"></div>
            </div>
        </b-modal>    
    </div>
</template>

<script>
export default {
    props:[
        'product', 
        'product_family_slug', 
        'product_family_class', 
        'product_family_identifier',
        'nutritionTable'
    ],
     data(){
        return{
            src_url: process.env.VUE_APP_BIFROST_API_URL_NO_PROJECTNAME
        }
    },
    computed:{
        nutritionData(){
            let _this = this
            let nutitionTableIdentifier = 'tapanyag-tablazat-' + this.product.slug + ''          

            if (_this.nutritionTable && _this.nutritionTable.length > 0) {            
                return _this.nutritionTable.find(item => item.identifier == nutitionTableIdentifier)
            } else {
                return null
            }
        }
    },
    methods:{
        setGtmDetails(){
              this.GTtrackGA4({
                'event' : 'event',
                'category': 'termekcsaladok_reszletek_oldal',
                'action': 'reszletek_kattintas',                
                'page_type': 'termekcsaladok_reszletek',
                'button': 'reszletek',            
                'clicked_text': 'Részletek',
                'action_type': 'gomb_kattintas'
            })
        },
        setGTMCart(product){
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'termekcsaladok_reszletek_oldal',
                'action': 'kosar_gombra_kattintas',                
                'page_type': 'termekcsaladok_reszletek',
                'button': 'kosar',            
                'product': product,
                'action_type': 'gomb_kattintas'
            })
        }
    }
}
</script>