<template>
    <div class="text-block "  v-if="currentProdFamily" :class="[currentProdFamily.identifier === $store.state.prodFamiliesId.ovatos_duhaj || currentProdFamily.identifier === $store.state.prodFamiliesId.radler_1_4 ? '' : 'text-block--right-text']">        
        <b-container fluid>        
            <b-row>
                <b-col cols="12" lg="6"  
                    v-inview="'fade-from-left'"
                    class="text-block__img-wrap"
                    v-if="currentProdFamily.image != null" 
                    :style="{'background-image': 'url(' + moduleBgr + ')' }" 
                >
                    <div class="text-block__img">                        
                        <img v-if="currentProdFamily.image"
                            :src="src_url + currentProdFamily.image.path + currentProdFamily.image.file_name" :alt="currentProdFamily.name" 
                            class="img-fluid"
                        />
                    </div>                    
                </b-col>
                <b-col cols="12" lg="6" class="text-block__content-wrap" v-inview="'fade-from-right'">                    
                    <div class="text-block__content" >
                        <img v-if="currentProdFamily.product_logo && needLogo"
                            :src="src_url + currentProdFamily.product_logo.path + currentProdFamily.product_logo.file_name" :alt="currentProdFamily.name" 
                            class="img-fluid prod-family__logo"
                        />
                        <h2>{{currentProdFamily.name}}</h2>
                        <div class="text" v-html="currentProdFamily.description" v-if="showDesc"></div>
                        <div class="text" v-html="currentProdFamily.short_description" v-else></div>
                        <div class="btn-wrap" v-if="showButtons">
                            <router-link                                 
                                :to="{name: 'ProductFamily', params:{product_family_slug : currentProdFamily.slug}}"
                                :class="[currentProdFamily.identifier === $store.state.prodFamiliesId.ovatos_duhaj ? 'btn btn--gold-border btn--with-arrow' : 'btn btn--green-border btn--with-arrow']"
                                @click.native="setGTM()"
                            >
                                <span>A termékcsaládhoz</span>
                            </router-link>                            
                            <a 
                                :href="currentProdFamily.shop_link" 
                                target="_blank"                                 
                                :class="[currentProdFamily.identifier === $store.state.prodFamiliesId.ovatos_duhaj ? 'btn btn--gold-border btn--shop no-text' : 'btn btn--green-border btn--shop no-text']"
                                @click="setGTMCart()"
                            ></a>
                        </div>
                        
                    </div>                            
                </b-col>
            </b-row>            
        </b-container>    
    </div>
</template>

<script>
export default {
    props:['currentProdFamily', 'showDesc', 'showButtons', 'needLogo'],
    data(){
        return{            
            src_url: process.env.VUE_APP_BIFROST_API_URL_NO_PROJECTNAME
        }
    },
    computed:{
        moduleBgr(){
            if (this.currentProdFamily.image_background){
                return this.src_url + this.currentProdFamily.image_background.path + this.currentProdFamily.image_background.file_name
            } else {
                return ""
            }
            
        }
    },
    methods:{
        setGTM(){            

            this.GTtrackGA4({
                'event' : 'event',
                'category': 'termekcsaladok_listaoldal',
                'action': 'termekcsalad_gombra_kattintas',                
                'page_type': 'termekcsaladok_lista',
                'button': 'termekcsalad',            
                'clicked_text': 'A termékcsaládhoz',
                'action_type': 'gomb_kattintas'
            })
        },
        setGTMCart(){
             this.GTtrackGA4({
                'event' : 'event',
                'category': 'termekcsaladok_listaoldal',
                'action': 'kosar_gombra_kattintas',                
                'page_type': 'termekcsaladok_lista',
                'button': 'kosar',                            
                'action_type': 'gomb_kattintas'
            })
        }
    }   
}
</script>