<template>
    <div class="slider slider--big">
        <b-carousel 
            :interval="4000"
            controls     
            indicators        
            :fade="fadeEffect"
        >     
            <b-carousel-slide
                v-for="sitem in sliderItems" :key="sitem.id"                                              
            >   
                <template #img>                                        
                    <img src="@/assets/images/noimage/big-slider-placeholder.png" class="main-img w-100"/>
                </template>         
                <a  v-if="sitem.target_url"
                    :href="sitem.target_url" 
                    class="slider__content" 
                    :style="{'background-image': 'url('+  src_url + sitem.image.path + sitem.image.file_name + ')'}" 
                    :target="hrefTarget(sitem.target_url)" 
                    @click="setGtm('')"
                >
                    <div class="container-xl slider__content-inner">
                        <b-row>
                            <b-col cols="12" md="6">
                                <div class="slider__inner-cont">
                                    <div class="slider__text-wrap">
                                        <div class="slider__title" v-html="sitem.name"></div>
                                        <div class="slider__text">{{sitem.description}}</div>
                                        <div class="btn btn--white-border btn--with-arrow"
                                            :class="[isDesctop ? 'btn btn--white-border btn--with-arrow' : 'btn btn--green-border btn--with-arrow']"
                                            @click.stop="setGtm('további részletek')"
                                        >
                                            <span>további részletek</span>
                                        </div>
                                    </div>                                    
                                </div>
                            </b-col>
                        </b-row>
                    </div>  
                    <div class="slider__img-right" v-if="sitem.image2">                                        
                        <img :src="src_url + sitem.image2.path + sitem.image2.file_name" class="content-img" />
                    </div>                  
                </a>
                <!-- no href -->
                <div v-else class="slider__content" 
                    :style="{'background-image': 'url('+  src_url + sitem.image.path + sitem.image.file_name + ')'}" 
                >
                    <div class="container-xl slider__content-inner">
                        <b-row>
                            <b-col cols="12" md="6">
                                <div class="slider__inner-cont">
                                    <div class="slider__text-wrap">
                                        <div class="slider__title">{{sitem.name}}</div>
                                        <div class="slider__text">{{sitem.description}}</div>
                                        <div class="btn "                                            
                                            @click.stop="setGtm('további részletek')"
                                            style="background: rgba(255, 255, 255, 0.15);"
                                        >
                                            <span style="color:white; cursor: initial">Hamarosan</span>
                                        </div>
                                    </div>                                    
                                </div>
                            </b-col>
                        </b-row>
                    </div>  
                    <div class="slider__img-right" v-if="sitem.image2">                                        
                        <img :src="src_url + sitem.image2.path + sitem.image2.file_name" class="content-img" />
                    </div>  
                </div>
            </b-carousel-slide>
        </b-carousel>
    </div>
</template>

<script>
export default {
    props:['sliderItems', 'fadeEffect'],    
    methods:{
        hrefTarget (link) {
            let _this = this
            
            return (_this.hasHttp(link) ? "_blank" : "_self")
        },
        setGtm(text){
            let _this = this

            this.GTtrackGA4({
                'event' : 'event',
                'category': _this.currentUrlName,
                'action': 'tovabbi_reszletek_kattintas',                
                'page_type': _this.currentUrlName,
                'button': 'tovabbi_reszletek',            
                'clicked_text': text,
                'action_type': 'gomb_kattintas'
            })
        }
    }
}
</script>
