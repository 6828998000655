export default {
  data(){
    return{
      currentPageModulesTexts: []       
    }
  },
    methods:{
      getModuls(modulesIdentifiers){                                               
          var _this = this               

          _this.get('module',
              {
                  params: {                          
                      relations:1,                                       
                      where: [
                        ['where','status', '1'],
                        ['where_in','identifier', modulesIdentifiers],                      
                      ]                    
                  }
              },
              function(resp){                                                 
                _this.currentPageModulesTexts = resp.data.module                                                  
              }, function(err){   
                  console.log(err)                   
                  _this.currentPageModulesTexts = []                  
              }              
          )                         
      },
     
    }   
}