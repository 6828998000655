<template>
    <div class="product-family page" v-if="currentProdFamily != null" :class="currentProdFamily.css_class" ref="prodFmPage">       
        <!-- TEXT BLOCK -->
        <section class="product-family-text-block__item" :class="currentProdFamily.css_class">
            <ProductFamilyTextBlock  
                :currentProdFamily="currentProdFamily" 
                :showDesc="true" 
                :showButtons="false" 
                :needLogo="true"
            />
        </section> 
         <!-- HERO -->
         <!-- video from product category table video field -->
        <section class="video-wrap" v-if="currentProdFamily.video && currentProdFamily.poster" v-inview="'fade-up'">        
            <Video 
                :video="src_url + currentProdFamily.video.path + currentProdFamily.video.file_name" 
                :staticVideoName="''" 
                :staticVideo="false" 
                :needScrollBtn="false" 
                :poster="src_url + currentProdFamily.poster.path + currentProdFamily.poster.file_name"                
            />
        </section> 
        <!-- video from relation table    -->
        <section class="video-wrap" v-if="currentProdFamily.product_category_video" v-inview="'fade-up'">        
            <div v-for="video in currentProdFamily.product_category_video" :key="video.id">
                <Video 
                    v-if="video.poster"
                    :video="src_url + video.video.path + video.video.file_name" 
                    :staticVideoName="''" 
                    :staticVideo="false" 
                    :needScrollBtn="false"  
                    :poster="src_url + video.poster.path + video.poster.file_name"  
                />
            </div>
        </section>  
        <!-- PRODUCTS  -->
        <section class="product-family__products">
            <div class="pf-little-item-wrap" v-for ="product in currentProdFamily.product" :key="product.id">
                <ProductLittle 
                    :product="product"                     
                    :product_family_slug="currentProdFamily.slug"
                    :product_family_class="currentProdFamily.css_class"
                    :product_family_identifier="currentProdFamily.identifier"
                    :nutritionTable="currentPageModulesTexts" 
                />
            </div>            
        </section>     
        <!-- Recipe offer - if the product family is Ovatos duhaj -->
        <RecipeOffer         
            v-if="currentPageModulesTexts.length > 0 && recipeCategories.length > 0"
            :identifier="'recept_ajanlo_ovatos_duhajoknak'" 
            :currentPageModulesTexts="currentPageModulesTexts" 
            :categories="recipeCategories"
            :recipes="recipes"
            v-inview="'fade-up'"
        />
        <!-- Related promos - games      -->
        <RelatedPromosGames :relPromosGames="promosGames" v-if="promosGames.length > 0" :type="''" />

        <!-- Related slider -->
        <section class="slider-wrap slider--very-big" v-if="bigSlider.length > 0" >
            <BigSlider :sliderItems="bigSlider" :fadeEffect="true" v-inview="'fade-up'"/>
        </section>  
        <!-- Other product families -->
        <section class="other-pdf-products" v-inview="'fade-up'">
            <h3>További termékcsaládok</h3>
        </section>
        <ProdFamilySlider :needApiCall="false"  :prodFamiliesAll="prodFamiliesAll"/>
        <PageLoading  />

        
    </div>
</template>

<script>
import apiH from '@/mixins/apiHandlerMI.js'
import viewMI from '@/mixins/viewMI.js'
import ProductFamilyTextBlock from '@/components/pages/productFamily/ProductFamilyTextBlock.vue'
import ProductLittle from '@/components/pages/productFamily/ProductLittle.vue'
import ProdFamilySlider from '@/components/pages/productFamily/ProdFamilySlider.vue'
import RelatedPromosGames from '@/components/pages/productFamily/RelatedPromosGames.vue'
import RecipeOffer from '@/components/layout/RecipeOffer.vue'
import Video from '@/components/layout/Video.vue'
import BigSlider from '@/components/pages/home/BigSlider.vue'
import PageLoading from '@/components/layout/PageLoading.vue'

export default {
    name: 'ProductFamily',
    mixins: [apiH,viewMI],
    data(){
        return{
            prodFamiliesAll: [],
            currentProdFamily: null,
            modulesIdentifiers: ['recept_ajanlo_ovatos_duhajoknak'],
            recipeCategories:[],
            recipes:[],
            promosGames:[],
            bigSlider: []            
            // loadCountNeed: 4,
            // currentPageLoadNum: 0      
        }
    },
    watch:{
        $route(){            
            this.prodFamiliesAll = [],
            this.currentProdFamily = null
            this.getProdFamiliesAll()            
        }
    },
    components:{
        ProductFamilyTextBlock,
        ProductLittle,
        ProdFamilySlider,
        RelatedPromosGames,
        RecipeOffer,
        Video,
        BigSlider,
        PageLoading
    },
    created(){
        let _this = this
        this.getProdFamiliesAll()

        if (_this.$route.params.product_family_slug == 'ovatos_duhaj'){
            this.getRecipeCategories()
            // this.getRecipeCategoriesVMI()
            this.getRecipes()
            this.getModuls(_this.modulesIdentifiers)    
        }
        
    },
    mounted(){
        
    },
    methods:{
        getProdFamiliesAll(){
            let _this = this

            _this.get('product_category',
                {
                    params: {                          
                        relations:1,                                       
                        where: [
                            ['where','status', '1'],                                                
                        ],
                        relations_params:{
                            product:{
                                select: ['name', 'shop_link', 'slug', 'card_image'],
                                where: [['where', 'status', '1']],
                                order_by:[['sort_order', 'asc'], ['name', 'asc']]
                            },
                            promotion:{
                                where:[['where', 'status', '1']],
                                order_by:[['sort_order', 'asc'], ['name', 'asc']]
                            },
                            game:{
                                where:[['where', 'status', '1']],
                                order_by:[['sort_order', 'asc'], ['name', 'asc']]
                            },
                            product_category_video: {
                                where:[['where', 'status', '1']],
                            }
                        }

                    }
                },
                function(resp){                                        
                    _this.prodFamiliesAll = resp.data.product_category
                    _this.getCurrentProdFamily_view()

                    //increase the page load num 
                    // _this.currentPageLoadNum += 1
                    
                }, function(err){
                    console.log(err);
                    _this.prodFamiliesAll = []
                }
            )
        },
        getCurrentProdFamily_view()  {
            let _this = this
            let currentSlug = this.$route.params.product_family_slug 

            // console.log('getcurrentprodfamily_view')
            this.prodFamiliesAll.forEach( element => {
                if (element.slug == currentSlug ) {
                    //get current product family
                    _this.currentProdFamily = element      
                    _this.getAllRelatedPromoGames()  
                    _this.setMetas()

                    if( _this.currentProdFamily.slider){
                        _this.loadSliders()            
                    }
                    
                    //remove the current prod family from the all prod families
                    var currentPFItemIndex =  _this.prodFamiliesAll.indexOf(element)
                    if (currentPFItemIndex > -1) {
                        _this.prodFamiliesAll.splice(currentPFItemIndex, 1);
                    }

                    _this.getNutritionTables()  
                }               
            })

             // 404 page
            if (!_this.currentProdFamily){
                // console.log("404 kell")
                _this.$router.push({name: '404-page'})
            }

        },
        getRecipeCategories(){
        let _this = this

        //because of route change - default state
        this.recipeCategories = []

        _this.get('recipe_category',
                {
                    params: {                          
                        relations:1,                                       
                        where: [
                            ['where','status', '1'],                                                                        
                        ],                        
                    }
                },
                function(resp){                                        
                    //we need the category if it has recipes
                    let allCategories = resp.data.recipe_category
                    allCategories.forEach(element => {
                        if (element.recipe) {
                        _this.recipeCategories.push(element)
                        }
                    });

                    //increase the page load num 
                    // _this.currentPageLoadNum += 1
                    
                }, function(err){
                    console.log(err);
                    _this.recipeCategories = []
                }
            )

        },
        getRecipes(){
            let _this = this

            //because of route change - default state
            this.recipes = []

            _this.get('recipe',
                    {
                        params: {                          
                            relations:1,                
                            limit:4,                       
                            where: [
                                ['where','status', '1'],   
                                ['where','image!=', '0'],                                                                     
                            ],
                            order_by:[
                                ['created_at','desc'],
                                ['sort_order','asc']                
                            ]
                        }
                    },
                    function(resp){                                        
                        _this.recipes = resp.data.recipe   
                        
                        //increase the page load num 
                        // _this.currentPageLoadNum += 1
                        
                    }, function(err){
                        console.log(err);
                        _this.recipes = []
                    }
                )

        },
        getAllRelatedPromoGames(){
            let _this = this
            let pg = []

            if (_this.currentProdFamily.promotion){
                _this.currentProdFamily.promotion.forEach(element => {
                    element.type = 'promotion'
                    pg.push(element)
                })
            }

            if (_this.currentProdFamily.game){
                _this.currentProdFamily.game.forEach(element => {
                    element.type = 'game'
                    pg.push(element)
                })
            }
                
            _this.promosGames = pg
        },
        loadSliders(){
            var _this = this;          
            
            this.bigSlider = []

            _this.get('slider',
                {
                    params: {                          
                        relations:1,                                       
                        where: [
                        ['where','status', '1'],
                        ['where','id', _this.currentProdFamily.slider.id],
                        ],
                        relations_params: {
                        slider_image: {
                            order_by:[
                                ['created_at','desc'],
                                ['sort_order','asc']                
                            ],
                            where: [['where','status', '1']]
                        }
                        }
                    }
                },
                function(resp){                           
                    if (resp.data.slider[0].slider_image){
                        _this.bigSlider = resp.data.slider[0].slider_image
                    } else {
                        _this.bigSlider = []
                    }                    

                    //increase the page load num 
                    // _this.currentPageLoadNum += 1
                    
                }, function(err){
                    console.log(err);
                    _this.bigSlider = []                    
                }
            )
        }, 
        getNutritionTables(){
            let _this = this
            //get the nutrition tables
                     
            this.currentProdFamily.product.forEach( element => {
                let modulIdText = 'tapanyag-tablazat-' + element.slug + ''   
                // console.log('modulIdText', modulIdText)             
                _this.modulesIdentifiers.push(modulIdText)                
            })  
                        
            _this.getModuls(_this.modulesIdentifiers)    
        },
        setMetas() {
            this.setMetaTags({
                title: this.currentProdFamily.name, 
                desc: this.currentProdFamily.meta_description, 
                keywords: this.currentProdFamily.meta_keywords, 
                ogType: 'website', 
                ogTitle: this.currentProdFamily.name,                         
                ogImage: this.currentProdFamily.meta_image, 
                ogImageWidth: null, 
                ogImageHeight: null, 
                ogDesc: this.currentProdFamily.meta_description
            })
        }
    }
}
</script>